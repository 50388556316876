footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background: local;
  color: #000;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  /* background-color: #146356; */
  font-family: Georgia, "Times New Roman", Times, serif;
  line-height: 1.7;
}

li {
  list-style: none;
  color: #966c3b;
}
.social-media {
  /* background-color: #d1dbf5; */
  color: #966c3b;
  padding: 1rem;
  background-color: #146356;
  text-align: center;
}
