.demo {
  background: linear-gradient(to right, #166256, #c38457, #575221, #8b4513);
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.panel {
  border: none;
  border-radius: 5px;
  box-shadow: none;
  margin-bottom: 10px;
  background: transparent;
}
.panel-heading {
  padding: 0;
  border: none;
  border-radius: 5px;
  background: transparent;
  position: relative;
}
.panel-title {
  display: block;
  padding: 20px 30px;
  margin: 0;
  background: rgba(0, 0, 0, 0.4);
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 5px;
  position: relative;
}
.accordion .panel-title a.collapsed {
  border: none;
}
.panel-title a:before,
.panel-title a.collapsed:before {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  width: 30px;
  height: 30px;
  line-height: 27px;
  text-align: center;
  font-size: 25px;
  font-weight: 900;
  color: #fff;
  position: absolute;
  top: 15px;
  right: 30px;
  transform: rotate(180deg);
  transition: all 0.4s cubic-bezier(0.08, 1.09, 0.32, 1.275);
}
.panel-title a.collapsed:before {
  color: rgba(255, 255, 255, 0.5);
  transform: rotate(0deg);
}
.panel-body {
  padding: 20px 30px;
  background: rgba(0, 0, 0, 0.1);
  font-size: 15px;
  color: #fff;
  line-height: 28px;
  letter-spacing: 1px;
  border-top: none;
  border-radius: 5px;
}
