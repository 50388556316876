.box-shadow {
  background-color: aquamarine;
  color: black;
  box-shadow: 2px 2px 4px rgb(183, 201, 255);
}
.shadow-color {
  background-color: none;
}

.navbar-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

li {
  float: left;
}

li a {
  display: block;

  color: #966c3b;
  /*   color: white; */
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.active-link {
  font-weight: 600;
  color: #146356;
}
.color-blue {
  color: #5c98ff;
}
nav {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  background-color: #ffffff;
  /*  background-color: #146356; */
}
li a:hover {
  font-weight: 600;
  color: #163307;
}
.logo {
  width: 100px;
  height: 100px;
  font-weight: 500;
  color: #bdede5;
  font-size: 18px;
}
