@import url("https://fonts.cdnfonts.com/css/nt-fabulous");
html {
  box-sizing: border-box;
}
@font-face {
  font-family: "YourFont";
  src: url("https://fonts.cdnfonts.com/s/95220/NT%20Fabulous%20(only%20personal%20use).woff")
    format("woff");
  font-display: swap;
}

body {
  color: #333;
  cursor: auto;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
p {
  font-size: 18px;
  font-family: "Jockey one";
  line-height: 1.7;
}
h1 {
  color: #005581;
  font-size: 40px;
  font-family: "NT Fabulous";
  font-weight: bolder;
}

h2 {
  color: #966c3b;
  font-family: fantasy;
}
h4 {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #966c3b;
}

.bg-beige {
  background-color: #f76e50;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.link-button {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 15px;
  color: #ffd282;
  background-color: #146356;

  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-family: "NT Fabulous";
  border-radius: 1px;
}
.height-vh {
  height: 100vh;
}
.sand {
  background-color: #f9f8f4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
