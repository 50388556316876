.img-one {
  width: 350;
  height: 300px;
}

.img-two {
  width: 200;
  height: 200px;
}
.card-one {
  background-color: #fbded9;
}
.card-two {
  background-color: #fbded9;
}
.card-four {
  background-color: #f3deba;
}
.card-three {
  background-color: #dbeede;
}
.card-border {
  border-radius: 15px 50px 15px 50px;
}
.container-video {
  position: relative;
  background-image: linear-gradient(
    #141619,
    rgb(41, 77, 140),
    #dc3545
  ) !important;
}
.container-video::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgb(237, 250, 255) 0%,
    rgba(255, 255, 255, 0) 69%
  );
  height: 100%;
}
figcaption {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  font-family: Arial, Helvetica, sans-serif;
  background-color: #e3e1db;
  opacity: 0.4;
}
figure {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
video {
  object-fit: cover;
  padding: 0;
  width: 100%;
  content: "";
  display: block;
  top: 0;
  left: 0;
  opacity: 0.8;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0) 69%
  );
  height: 600;
}

.glassy-text {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  color: #3468c0;
  font-family: system-ui, sans-serif;
  line-height: 1.5;
  max-width: 60%;
  padding: 1rem 2rem;
}
.card-container {
  display: flex;
}

.cards {
  flex: 1;
}
